import { render, staticRenderFns } from "./director.vue?vue&type=template&id=013fb639&scoped=true"
import script from "./director.vue?vue&type=script&lang=js"
export * from "./director.vue?vue&type=script&lang=js"
import style0 from "./director.vue?vue&type=style&index=0&id=013fb639&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013fb639",
  null
  
)

export default component.exports